<template>
  <v-container>
    <div class="data-drid">
      <dx-data-grid
        ref="refDiary"
        :data-source="planInfoList"
        :show-borders="true"
        :show-row-lines="false"
        :show-column-lines="true"
        :hover-state-enabled="true"
        :allow-column-resizing="true"
        column-resizing-mode="widget"
        :allow-column-reordering="true"
        :height="gridHeight"
        :row-alternation-enabled="true"
        :focused-row-enabled="true"
        :auto-navigate-to-focused-row="false"
        key-expr="품번"
      >
        <DxSearchPanel
          :visible="false"
          placeholder="검색..."
        />
        <DxColumnFixing :enabled="true" />
        <DxColumn
          :allow-editing="false"
          :fixed="false"
          sort-order="asc"
          caption="품번"
          data-field="품번"
          width="140"
        />
        <DxColumn
          caption="주간 생산계획"
          alignment="center"
        >
          <DxColumn
            v-for="(item, index) in dateArray"
            :key="index"
            :caption="item.caption"
            :data-field="item.dataField"
            :data-type="item.dataType"
            :format="item.format"
            :allow-sorting="false"
            width="60"
          />
        </DxColumn>
        <DxColumn
          :allow-editing="false"
          caption="품명"
          data-field="품명"
          width="140"
        />
        <dx-paging :enabled="false" />
        <dx-scrolling
          mode="virtuala"
        />
        <dx-sorting mode="multiple" />
      </dx-data-grid>
    </div>
    <loading
      :active.sync="isLoading"
      color="rgb(2, 110, 156)"
      loader="bars"
    />
  </v-container>
</template>
<script>
import Loading from 'vue-loading-overlay'
import AppLib from '../../share/AppLib'
import ConstDef from '../../../../MesSmartVue/src/share/ConstDef'
import axios from 'axios'
import DxDataGrid, {
  DxScrolling, DxColumnFixing,
  DxColumn, DxPaging, DxSorting, DxSearchPanel
} from 'devextreme-vue/data-grid'

export default {
  name: 'MainPage',
  components: {
    Loading,
    DxDataGrid,
    DxScrolling,
    DxColumnFixing,
    DxColumn,
    DxPaging,
    DxSorting,
    DxSearchPanel
  },
  data: function () {
    return {
      isLoading: false,
      userInfo: null,
      planInfoList: null,
      gridHeight: null,
      today: null,
      dateArray: null,
      thisSundayDate: null,
      weekDays: null,
      thisWeekendDate: null,
      nextWeekendDate: null
    }
  },
  computed: {
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
      return
    }
    this.today = AppLib.getToday()
    this.calcNextWeekendDay(this.today)
    this.refreshWeekPlan()
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  },
  updated () {

  },
  methods: {
    handleResize () {
      this.gridHeight = (this.$Q('html').height() - 90) - 30
    },
    calcNextWeekendDay (day) {
      const week = AppLib.getWeekFromDateFormat(day)
      this.thisSundayDate = AppLib.calcDateFromToday(week * -1)
      this.weekDays = (7 - week) + 7
      this.thisWeekendDate = AppLib.calcDateFromToday(6 - week)
      this.nextWeekendDate = AppLib.calcDateFromToday((6 - week) + 7)
    },
    initDateArray () {
      this.dateArray = []
      for (let j = 0; j < this.weekDays; j++) {
        let day = AppLib.calcDateFromFormat(this.today, j)
        day = `${day.substring(5, 7)}/${day.substring(8, 10)}`
        const dateTemp = {
          caption: day,
          dataField: day,
          format: '#,##0',
          dataType: 'number'
        }
        this.dateArray.push(dateTemp)
      }
    },
    makeWeekModel (planInfo, product) { // planInfo ={생산일자 품번 품명 수량}
      this.planInfoList = []
      this.initDateArray()
      if (planInfo === null) {
        for (let i = 0; i < product.length; i++) {
          const temp = {
            품번: product[i].품번,
            품명: product[i].품명
          }
          for (let j = 0; j < this.weekDays; j++) {
            temp.생산일자 = AppLib.calcDateFromFormat(this.today, j)
            const key = `${temp.생산일자.substring(5, 7)}/${temp.생산일자.substring(8, 10)}`
            temp[key] = null
          }
          this.planInfoList.push(temp)
        }
        return
      }
      for (let i = 0; i < product.length; i++) {
        const temp = {
          품번: product[i].품번,
          품명: product[i].품명
        }
        for (let j = 0; j < this.weekDays; j++) {
          temp.생산일자 = AppLib.calcDateFromFormat(this.today, j)
          const timeStamp = AppLib.getTimestampFromString(`${temp.생산일자} 00:00:00`)
          const key = `${temp.생산일자.substring(5, 7)}/${temp.생산일자.substring(8, 10)}`
          let where = this.$_.findWhere(planInfo, { 생산일자: timeStamp, 품번: product[i].품번 })
          if (where === undefined) {
            where = this.$_.findWhere(planInfo, { 생산일자: temp.생산일자, 품번: product[i].품번 })
          }
          if (where === undefined) {
            temp[key] = null
          } else {
            temp[key] = where.수량
          }
        }
        this.planInfoList.push(temp)
      }
    },
    refreshWeekPlan () {
      const param = {
        sunday: this.thisSundayDate,
        fromDate: this.today,
        toDate: this.nextWeekendDate,
        goodsType: ConstDef.완제품
      }
      this.isLoading = true
      const thisWeek = this.$_sp.runNoEncodeFindProc('spFindAllWeekPlanned', param)
      const product = this.$_sp.runNoEncodeFindProc('spFindAllProductByType', { 품목유형: ConstDef.완제품 })
      axios.all([thisWeek, product])
        .then(axios.spread((...reponse) => {
          this.isLoading = false
          const thisWeek = this.$_sp.MakeModel(reponse[0])
          this.productInfo = this.$_sp.MakeModel(reponse[1])
          this.makeWeekModel(thisWeek, this.productInfo)
        }))
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    }
  }
}
</script>

<style lang="scss">
  .data-grid {
    margin-top:0px;
  }
</style>
